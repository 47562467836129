import { Form, Formik } from "formik";
import * as Yup from "yup";
import SelectInput from "../../UI/common/form/SelectInput";
import TextareaInput from "../../UI/common/form/TextareaInput";
import TextInput from "../../UI/common/form/TextInput";

const JOB_TYPE = [
  { text: "freelance", value: "Freelance" },
  { text: "personal", value: "Personal" },
  { text: "developer", value: "Developer" },
];

type RegisterForm = {
  fullname: string;
  username: string;
  password: string;
  jobtype: string;
  initialAssetValue: number;
  description: string;
};

const Register = () => {
  const intialRegisterValue: RegisterForm = {
    fullname: "",
    username: "",
    password: "",
    jobtype: "",
    description: "",
    initialAssetValue: 0,
  };

  const validationScheme = Yup.object({
    fullname: Yup.string().required("Shoudl be enetered your name"),
    username: Yup.string().required(),
    password: Yup.string().required().length(5),
    jobtype: Yup.string().required(),
    description: Yup.string().required().max(20),
    initialAssetValue: Yup.number()
      .required()
      .min(1, "Should be have positive balance"),
  });
  return (
    <div>
      <h2>Register your user</h2>
      <span>
        By using your own user, you can achieve more to do with this application
      </span>
      <Formik
        validationSchema={validationScheme}
        enableReinitialize
        initialValues={intialRegisterValue}
        onSubmit={(values) => console.log(values)}
      >
        {({ handleSubmit, dirty, isSubmitting, isValid }) => {
          return (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <TextInput name="fullname" placeholder="Full Name" />
              <TextInput name="username" placeholder="Username" />
              <TextInput
                type="password"
                name="password"
                placeholder="Password"
              />
              <SelectInput
                name="jobtype"
                placeholder="Job Type"
                options={JOB_TYPE}
              />
              <TextareaInput
                name="description"
                placeholder="Description"
                rows={3}
              />
              <TextInput
                type="number"
                name="initialAssetValue"
                placeholder="Initial Asset Value"
              />
              <button type="reset">Reset</button>
              <button
                type="submit"
                disabled={isSubmitting || !dirty || !isValid}
              >
                Submit
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Register;
