import React from "react";

interface Props {
  name: string;
  placeholder?: string;
  value: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "text" |  "number" | "password";
  error?: string;
  [props: string]: any;
}

const Input = ({
  name,
  onChangeHandler,
  placeholder,
  value,
  type = "text",
  error,
  ...props
}: Props) => {
  return (
    <>
      <div className="ui input">
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          value={value || ""}
          onChange={onChangeHandler}
          {...props}
        />
      </div>
      {error && (
        <div className="ui red pointing basic label right">{error}</div>
      )}
    </>
  );
};

export default Input;
