export const DUMMY_DATA = [
  {
    id: 1,
    name: "Bitcoin",
    symbol: "BTC",
    slug: "bitcoin",
    num_market_pairs: 8960,
    date_added: "2013-04-28T00:00:00.000Z",
    tags: [
      "mineable",
      "pow",
      "sha-256",
      "store-of-value",
      "state-channels",
      "coinbase-ventures-portfolio",
      "three-arrows-capital-portfolio",
      "polychain-capital-portfolio",
      "binance-labs-portfolio",
      "arrington-xrp-capital",
      "blockchain-capital-portfolio",
      "boostvc-portfolio",
      "cms-holdings-portfolio",
      "dcg-portfolio",
      "dragonfly-capital-portfolio",
      "electric-capital-portfolio",
      "fabric-ventures-portfolio",
      "framework-ventures",
      "galaxy-digital-portfolio",
      "huobi-capital",
      "alameda-research-portfolio",
      "a16z-portfolio",
      "1confirmation-portfolio",
      "winklevoss-capital",
      "usv-portfolio",
      "placeholder-ventures-portfolio",
      "pantera-capital-portfolio",
      "multicoin-capital-portfolio",
      "paradigm-xzy-screener",
    ],
    max_supply: 21000000,
    circulating_supply: 18772862,
    total_supply: 18772862,
    platform: null,
    cmc_rank: 1,
    last_updated: "2021-08-01T04:55:08.000Z",
    quote: {
      USD: {
        price: 42203.923503403545,
        volume_24h: 25110850371.288975,
        percent_change_1h: -0.38530286,
        percent_change_24h: 0.95065397,
        percent_change_7d: 22.78790684,
        percent_change_30d: 27.44579468,
        percent_change_60d: 15.02120022,
        percent_change_90d: -27.03846654,
        market_cap: 792288431787.9513,
        last_updated: "2021-08-01T04:55:08.000Z",
      },
    },
  },
  {
    id: 1027,
    name: "Ethereum",
    symbol: "ETH",
    slug: "ethereum",
    num_market_pairs: 5635,
    date_added: "2015-08-07T00:00:00.000Z",
    tags: [
      "mineable",
      "pow",
      "smart-contracts",
      "ethereum",
      "coinbase-ventures-portfolio",
      "three-arrows-capital-portfolio",
      "polychain-capital-portfolio",
      "binance-labs-portfolio",
      "arrington-xrp-capital",
      "blockchain-capital-portfolio",
      "boostvc-portfolio",
      "cms-holdings-portfolio",
      "dcg-portfolio",
      "dragonfly-capital-portfolio",
      "electric-capital-portfolio",
      "fabric-ventures-portfolio",
      "framework-ventures",
      "hashkey-capital-portfolio",
      "kinetic-capital",
      "huobi-capital",
      "alameda-research-portfolio",
      "a16z-portfolio",
      "1confirmation-portfolio",
      "winklevoss-capital",
      "usv-portfolio",
      "placeholder-ventures-portfolio",
      "pantera-capital-portfolio",
      "multicoin-capital-portfolio",
      "paradigm-xzy-screener",
    ],
    max_supply: null,
    circulating_supply: 116923037.5615,
    total_supply: 116923037.5615,
    platform: null,
    cmc_rank: 2,
    last_updated: "2021-08-01T04:55:06.000Z",
    quote: {
      USD: {
        price: 2629.451942395565,
        volume_24h: 19183146568.030952,
        percent_change_1h: 0.83502888,
        percent_change_24h: 7.12419866,
        percent_change_7d: 21.37905638,
        percent_change_30d: 28.29103032,
        percent_change_60d: 0.04990342,
        percent_change_90d: -13.9127896,
        market_cap: 307443508226.87573,
        last_updated: "2021-08-01T04:55:06.000Z",
      },
    },
  },
  {
    id: 825,
    name: "Tether",
    symbol: "USDT",
    slug: "tether",
    num_market_pairs: 16037,
    date_added: "2015-02-25T00:00:00.000Z",
    tags: [
      "payments",
      "stablecoin",
      "stablecoin-asset-backed",
      "avalanche-ecosystem",
      "solana-ecosystem",
    ],
    max_supply: null,
    circulating_supply: 61871052217.688835,
    total_supply: 64469737785.68351,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    },
    cmc_rank: 3,
    last_updated: "2021-08-01T04:54:56.000Z",
    quote: {
      USD: {
        price: 1.00015486882063,
        volume_24h: 52601150997.20841,
        percent_change_1h: -0.00286662,
        percent_change_24h: -0.0430328,
        percent_change_7d: -0.00312009,
        percent_change_30d: -0.03026562,
        percent_change_60d: 0.00422617,
        percent_change_90d: 0.00978084,
        market_cap: 61880634114.576935,
        last_updated: "2021-08-01T04:54:56.000Z",
      },
    },
  },
  {
    id: 1839,
    name: "Binance Coin",
    symbol: "BNB",
    slug: "binance-coin",
    num_market_pairs: 537,
    date_added: "2017-07-25T00:00:00.000Z",
    tags: [
      "marketplace",
      "centralized-exchange",
      "payments",
      "binance-smart-chain",
      "alameda-research-portfolio",
      "multicoin-capital-portfolio",
    ],
    max_supply: 168137036,
    circulating_supply: 168137036,
    total_supply: 168137036,
    platform: null,
    cmc_rank: 4,
    last_updated: "2021-08-01T04:54:49.000Z",
    quote: {
      USD: {
        price: 344.70490702902464,
        volume_24h: 1884158735.56078,
        percent_change_1h: -0.73352899,
        percent_change_24h: 6.90093579,
        percent_change_7d: 14.9222298,
        percent_change_30d: 22.96019897,
        percent_change_60d: -4.43240803,
        percent_change_90d: -44.81296959,
        market_cap: 57957661362.51577,
        last_updated: "2021-08-01T04:54:49.000Z",
      },
    },
  },
  {
    id: 2010,
    name: "Cardano",
    symbol: "ADA",
    slug: "cardano",
    num_market_pairs: 285,
    date_added: "2017-10-01T00:00:00.000Z",
    tags: [
      "mineable",
      "dpos",
      "pos",
      "platform",
      "research",
      "smart-contracts",
      "staking",
      "binance-smart-chain",
    ],
    max_supply: 45000000000,
    circulating_supply: 32065763023.623,
    total_supply: 32936637337.181,
    platform: null,
    cmc_rank: 5,
    last_updated: "2021-08-01T04:54:44.000Z",
    quote: {
      USD: {
        price: 1.37901433373503,
        volume_24h: 1462116945.432816,
        percent_change_1h: -0.34019419,
        percent_change_24h: 5.24907942,
        percent_change_7d: 12.48599428,
        percent_change_30d: 5.5368429,
        percent_change_60d: -21.6890597,
        percent_change_90d: 2.77197695,
        market_cap: 44219146831.72684,
        last_updated: "2021-08-01T04:54:44.000Z",
      },
    },
  },
  {
    id: 52,
    name: "XRP",
    symbol: "XRP",
    slug: "xrp",
    num_market_pairs: 632,
    date_added: "2013-08-04T00:00:00.000Z",
    tags: [
      "medium-of-exchange",
      "enterprise-solutions",
      "binance-chain",
      "arrington-xrp-capital",
      "galaxy-digital-portfolio",
      "a16z-portfolio",
      "pantera-capital-portfolio",
    ],
    max_supply: 100000000000,
    circulating_supply: 46312443360,
    total_supply: 99990333192,
    platform: null,
    cmc_rank: 6,
    last_updated: "2021-08-01T04:54:32.000Z",
    quote: {
      USD: {
        price: 0.76812375372428,
        volume_24h: 2673487381.0002975,
        percent_change_1h: -0.92946667,
        percent_change_24h: 2.39390368,
        percent_change_7d: 26.92363878,
        percent_change_30d: 19.09056268,
        percent_change_60d: -23.63224926,
        percent_change_90d: -52.23427698,
        market_cap: 35573687837.82631,
        last_updated: "2021-08-01T04:54:32.000Z",
      },
    },
  },
  {
    id: 74,
    name: "Dogecoin",
    symbol: "DOGE",
    slug: "dogecoin",
    num_market_pairs: 378,
    date_added: "2013-12-15T00:00:00.000Z",
    tags: [
      "mineable",
      "pow",
      "scrypt",
      "medium-of-exchange",
      "memes",
      "payments",
      "doggone-doggerel",
    ],
    max_supply: null,
    circulating_supply: 130666722045.7413,
    total_supply: 130666722045.7413,
    platform: null,
    cmc_rank: 7,
    last_updated: "2021-08-01T04:54:45.000Z",
    quote: {
      USD: {
        price: 0.21555292483759,
        volume_24h: 1484092203.057796,
        percent_change_1h: -0.59013368,
        percent_change_24h: 3.74966099,
        percent_change_7d: 8.76277498,
        percent_change_30d: -11.03483994,
        percent_change_60d: -41.69041237,
        percent_change_90d: -43.74124973,
        market_cap: 28165594115.89994,
        last_updated: "2021-08-01T04:54:45.000Z",
      },
    },
  },
  {
    id: 3408,
    name: "USD Coin",
    symbol: "USDC",
    slug: "usd-coin",
    num_market_pairs: 1333,
    date_added: "2018-10-08T00:00:00.000Z",
    tags: ["medium-of-exchange", "stablecoin", "stablecoin-asset-backed"],
    max_supply: null,
    circulating_supply: 27368303517.898598,
    total_supply: 27368303517.898598,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    },
    cmc_rank: 8,
    last_updated: "2021-08-01T04:54:45.000Z",
    quote: {
      USD: {
        price: 0.99952326996301,
        volume_24h: 2150518336.7651997,
        percent_change_1h: 0.00551454,
        percent_change_24h: -0.04674938,
        percent_change_7d: -0.01640057,
        percent_change_30d: -0.09205301,
        percent_change_60d: -0.07517133,
        percent_change_90d: -0.05879962,
        market_cap: 27355256225.550156,
        last_updated: "2021-08-01T04:54:45.000Z",
      },
    },
  },
  {
    id: 6636,
    name: "Polkadot",
    symbol: "DOT",
    slug: "polkadot-new",
    num_market_pairs: 231,
    date_added: "2020-08-19T00:00:00.000Z",
    tags: [
      "substrate",
      "polkadot",
      "binance-chain",
      "polkadot-ecosystem",
      "three-arrows-capital-portfolio",
      "polychain-capital-portfolio",
      "blockchain-capital-portfolio",
      "boostvc-portfolio",
      "cms-holdings-portfolio",
      "coinfund-portfolio",
      "fabric-ventures-portfolio",
      "fenbushi-capital-portfolio",
      "hashkey-capital-portfolio",
      "kinetic-capital",
      "1confirmation-portfolio",
      "placeholder-ventures-portfolio",
      "pantera-capital-portfolio",
      "exnetwork-capital-portfolio",
    ],
    max_supply: null,
    circulating_supply: 980057054.9546391,
    total_supply: 1095781211.379827,
    platform: null,
    cmc_rank: 9,
    last_updated: "2021-08-01T04:54:46.000Z",
    quote: {
      USD: {
        price: 18.91164187282381,
        volume_24h: 1867536744.3379366,
        percent_change_1h: 0.82539905,
        percent_change_24h: 18.73510304,
        percent_change_7d: 40.17531046,
        percent_change_30d: 27.13280363,
        percent_change_60d: -20.15012672,
        percent_change_90d: -50.16476407,
        market_cap: 18534488038.236538,
        last_updated: "2021-08-01T04:54:46.000Z",
      },
    },
  },
  {
    id: 7083,
    name: "Uniswap",
    symbol: "UNI",
    slug: "uniswap",
    num_market_pairs: 288,
    date_added: "2020-09-17T00:00:00.000Z",
    tags: [
      "decentralized-exchange",
      "defi",
      "dao",
      "yield-farming",
      "amm",
      "coinbase-ventures-portfolio",
      "three-arrows-capital-portfolio",
      "governance",
      "blockchain-capital-portfolio",
      "defiance-capital",
      "alameda-research-portfolio",
      "a16z-portfolio",
      "pantera-capital-portfolio",
      "parafi-capital",
      "paradigm-xzy-screener",
    ],
    max_supply: 1000000000,
    circulating_supply: 587376040.574713,
    total_supply: 1000000000,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    },
    cmc_rank: 10,
    last_updated: "2021-08-01T04:54:34.000Z",
    quote: {
      USD: {
        price: 22.53901165852139,
        volume_24h: 378541480.05412835,
        percent_change_1h: 0.24269737,
        percent_change_24h: 6.44079811,
        percent_change_7d: 24.10554771,
        percent_change_30d: 30.18983907,
        percent_change_60d: -16.97829612,
        percent_change_90d: -49.4462723,
        market_cap: 13238875426.449589,
        last_updated: "2021-08-01T04:54:34.000Z",
      },
    },
  },
  {
    id: 4687,
    name: "Binance USD",
    symbol: "BUSD",
    slug: "binance-usd",
    num_market_pairs: 922,
    date_added: "2019-09-20T00:00:00.000Z",
    tags: [
      "stablecoin",
      "stablecoin-asset-backed",
      "binance-chain",
      "binance-smart-chain",
    ],
    max_supply: null,
    circulating_supply: 12285746621.84,
    total_supply: 12285746621.84,
    platform: {
      id: 1839,
      name: "Binance Chain",
      symbol: "BNB",
      slug: "binance-coin",
      token_address: "BUSD-BD1",
    },
    cmc_rank: 11,
    last_updated: "2021-08-01T04:54:49.000Z",
    quote: {
      USD: {
        price: 0.99965484112463,
        volume_24h: 3953750908.570516,
        percent_change_1h: 0.00525216,
        percent_change_24h: -0.0586398,
        percent_change_7d: -0.00136063,
        percent_change_30d: -0.07090494,
        percent_change_60d: -0.06770695,
        percent_change_90d: -0.0537724,
        market_cap: 12281506087.352924,
        last_updated: "2021-08-01T04:54:49.000Z",
      },
    },
  },
  {
    id: 1831,
    name: "Bitcoin Cash",
    symbol: "BCH",
    slug: "bitcoin-cash",
    num_market_pairs: 561,
    date_added: "2017-07-23T00:00:00.000Z",
    tags: [
      "mineable",
      "pow",
      "sha-256",
      "marketplace",
      "medium-of-exchange",
      "store-of-value",
      "enterprise-solutions",
      "payments",
      "binance-chain",
    ],
    max_supply: 21000000,
    circulating_supply: 18805075,
    total_supply: 18805075,
    platform: null,
    cmc_rank: 12,
    last_updated: "2021-08-01T04:54:47.000Z",
    quote: {
      USD: {
        price: 559.5666597860559,
        volume_24h: 1712476877.3473876,
        percent_change_1h: -0.48904461,
        percent_change_24h: 2.1821623,
        percent_change_7d: 23.59097308,
        percent_change_30d: 14.86666707,
        percent_change_60d: -18.52740909,
        percent_change_90d: -43.93178242,
        market_cap: 10522693004.776264,
        last_updated: "2021-08-01T04:54:47.000Z",
      },
    },
  },
  {
    id: 1975,
    name: "Chainlink",
    symbol: "LINK",
    slug: "chainlink",
    num_market_pairs: 525,
    date_added: "2017-09-20T00:00:00.000Z",
    tags: [
      "platform",
      "defi",
      "oracles",
      "smart-contracts",
      "substrate",
      "polkadot",
      "polkadot-ecosystem",
      "avalanche-ecosystem",
      "solana-ecosystem",
      "framework-ventures",
      "polygon-ecosystem",
    ],
    max_supply: 1000000000,
    circulating_supply: 443009553.9174637,
    total_supply: 1000000000,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0x514910771af9ca656af840dff83e8264ecf986ca",
    },
    cmc_rank: 13,
    last_updated: "2021-08-01T04:54:39.000Z",
    quote: {
      USD: {
        price: 23.40089228717248,
        volume_24h: 1282673406.7074955,
        percent_change_1h: -0.26358023,
        percent_change_24h: 6.68537597,
        percent_change_7d: 40.47959906,
        percent_change_30d: 31.60747237,
        percent_change_60d: -23.39095376,
        percent_change_90d: -41.79364933,
        market_cap: 10366818853.410896,
        last_updated: "2021-08-01T04:54:39.000Z",
      },
    },
  },
  {
    id: 2,
    name: "Litecoin",
    symbol: "LTC",
    slug: "litecoin",
    num_market_pairs: 712,
    date_added: "2013-04-28T00:00:00.000Z",
    tags: ["mineable", "pow", "scrypt", "medium-of-exchange", "binance-chain"],
    max_supply: 84000000,
    circulating_supply: 66752414.51538747,
    total_supply: 66752414.51538747,
    platform: null,
    cmc_rank: 14,
    last_updated: "2021-08-01T04:55:03.000Z",
    quote: {
      USD: {
        price: 148.95648490122514,
        volume_24h: 1385589227.3722603,
        percent_change_1h: -0.30847771,
        percent_change_24h: 2.9514629,
        percent_change_7d: 18.37654485,
        percent_change_30d: 12.46380977,
        percent_change_60d: -18.61635442,
        percent_change_90d: -45.39796322,
        market_cap: 9943205024.881636,
        last_updated: "2021-08-01T04:55:03.000Z",
      },
    },
  },
  {
    id: 5426,
    name: "Solana",
    symbol: "SOL",
    slug: "solana",
    num_market_pairs: 104,
    date_added: "2020-04-10T00:00:00.000Z",
    tags: [
      "dpos",
      "platform",
      "solana-ecosystem",
      "cms-holdings-portfolio",
      "kinetic-capital",
      "alameda-research-portfolio",
      "multicoin-capital-portfolio",
    ],
    max_supply: null,
    circulating_supply: 272637427.57725775,
    total_supply: 494519161.74206734,
    platform: null,
    cmc_rank: 15,
    last_updated: "2021-08-01T04:55:05.000Z",
    quote: {
      USD: {
        price: 35.72471413364293,
        volume_24h: 605768314.1103331,
        percent_change_1h: -1.37115562,
        percent_change_24h: 10.55188968,
        percent_change_7d: 27.5672391,
        percent_change_30d: 10.52125709,
        percent_change_60d: 14.98855565,
        percent_change_90d: -25.75644683,
        market_cap: 9739894162.32931,
        last_updated: "2021-08-01T04:55:05.000Z",
      },
    },
  },
  {
    id: 3717,
    name: "Wrapped Bitcoin",
    symbol: "WBTC",
    slug: "wrapped-bitcoin",
    num_market_pairs: 236,
    date_added: "2019-01-30T00:00:00.000Z",
    tags: ["medium-of-exchange", "defi", "wrapped-tokens"],
    max_supply: null,
    circulating_supply: 192574.22484399,
    total_supply: 192574.22484399,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    },
    cmc_rank: 16,
    last_updated: "2021-08-01T04:54:46.000Z",
    quote: {
      USD: {
        price: 42222.049048191184,
        volume_24h: 333969277.3650613,
        percent_change_1h: -0.16144224,
        percent_change_24h: 1.09364802,
        percent_change_7d: 22.9129835,
        percent_change_30d: 27.71571474,
        percent_change_60d: 15.11661343,
        percent_change_90d: -27.64127262,
        market_cap: 8130878366.780343,
        last_updated: "2021-08-01T04:54:46.000Z",
      },
    },
  },
  {
    id: 3890,
    name: "Polygon",
    symbol: "MATIC",
    slug: "polygon",
    num_market_pairs: 215,
    date_added: "2019-04-28T00:00:00.000Z",
    tags: [
      "platform",
      "enterprise-solutions",
      "state-channels",
      "coinbase-ventures-portfolio",
      "binance-launchpad",
      "binance-labs-portfolio",
      "polygon-ecosystem",
    ],
    max_supply: 10000000000,
    circulating_supply: 6424735804,
    total_supply: 10000000000,
    platform: null,
    cmc_rank: 17,
    last_updated: "2021-08-01T04:54:45.000Z",
    quote: {
      USD: {
        price: 1.12200753890517,
        volume_24h: 733707316.1551706,
        percent_change_1h: 0.33529699,
        percent_change_24h: 7.66012893,
        percent_change_7d: 19.40265795,
        percent_change_30d: 5.31813049,
        percent_change_60d: -37.52264682,
        percent_change_90d: 36.31672042,
        market_cap: 7208602007.561969,
        last_updated: "2021-08-01T04:54:45.000Z",
      },
    },
  },
  {
    id: 512,
    name: "Stellar",
    symbol: "XLM",
    slug: "stellar",
    num_market_pairs: 350,
    date_added: "2014-08-05T00:00:00.000Z",
    tags: [
      "medium-of-exchange",
      "enterprise-solutions",
      "decentralized-exchange",
      "smart-contracts",
      "hashkey-capital-portfolio",
    ],
    max_supply: 50001806812,
    circulating_supply: 23393654215.23043,
    total_supply: 50001802945.77713,
    platform: null,
    cmc_rank: 18,
    last_updated: "2021-08-01T04:55:09.000Z",
    quote: {
      USD: {
        price: 0.29697494174121,
        volume_24h: 616064614.638647,
        percent_change_1h: -0.9943353,
        percent_change_24h: 5.60664503,
        percent_change_7d: 13.48733866,
        percent_change_30d: 12.79586999,
        percent_change_60d: -28.27621595,
        percent_change_90d: -47.36500981,
        market_cap: 6947329097.682068,
        last_updated: "2021-08-01T04:55:09.000Z",
      },
    },
  },
  {
    id: 1321,
    name: "Ethereum Classic",
    symbol: "ETC",
    slug: "ethereum-classic",
    num_market_pairs: 276,
    date_added: "2016-07-24T00:00:00.000Z",
    tags: [
      "mineable",
      "pow",
      "ethash",
      "platform",
      "smart-contracts",
      "dcg-portfolio",
    ],
    max_supply: 210700000,
    circulating_supply: 128674508.84306523,
    total_supply: 210700000,
    platform: null,
    cmc_rank: 19,
    last_updated: "2021-08-01T04:54:44.000Z",
    quote: {
      USD: {
        price: 53.30994404120826,
        volume_24h: 2314613690.463457,
        percent_change_1h: -0.85155957,
        percent_change_24h: 5.03247586,
        percent_change_7d: 10.38350671,
        percent_change_30d: 2.0571763,
        percent_change_60d: -20.72781355,
        percent_change_90d: 17.8290581,
        market_cap: 6859630865.953765,
        last_updated: "2021-08-01T04:54:44.000Z",
      },
    },
  },
  {
    id: 2416,
    name: "THETA",
    symbol: "THETA",
    slug: "theta",
    num_market_pairs: 72,
    date_added: "2018-01-17T00:00:00.000Z",
    tags: [
      "media",
      "collectibles-nfts",
      "content-creation",
      "video",
      "huobi-capital",
    ],
    max_supply: 1000000000,
    circulating_supply: 1000000000,
    total_supply: 1000000000,
    platform: null,
    cmc_rank: 20,
    last_updated: "2021-08-01T04:54:35.000Z",
    quote: {
      USD: {
        price: 6.2079735378828,
        volume_24h: 343854972.1325735,
        percent_change_1h: -1.01540793,
        percent_change_24h: 5.42811291,
        percent_change_7d: 2.11344065,
        percent_change_30d: 3.54860092,
        percent_change_60d: -20.93061594,
        percent_change_90d: -44.83877309,
        market_cap: 6207973537.882799,
        last_updated: "2021-08-01T04:54:35.000Z",
      },
    },
  },
  {
    id: 8916,
    name: "Internet Computer",
    symbol: "ICP",
    slug: "internet-computer",
    num_market_pairs: 61,
    date_added: "2021-03-23T00:00:00.000Z",
    tags: [
      "platform",
      "distributed-computing",
      "polychain-capital-portfolio",
      "exnetwork-capital-portfolio",
    ],
    max_supply: 469213710,
    circulating_supply: 136899213.55,
    total_supply: 471547504.6714462,
    platform: null,
    cmc_rank: 21,
    last_updated: "2021-08-01T04:54:45.000Z",
    quote: {
      USD: {
        price: 43.79685747062417,
        volume_24h: 269937835.6337811,
        percent_change_1h: 0.38431531,
        percent_change_24h: 7.1534922,
        percent_change_7d: 1.7619945,
        percent_change_30d: 0.45258267,
        percent_change_60d: -59.10814213,
        percent_change_90d: 0,
        market_cap: 5995755343.689892,
        last_updated: "2021-08-01T04:54:45.000Z",
      },
    },
  },
  {
    id: 3077,
    name: "VeChain",
    symbol: "VET",
    slug: "vechain",
    num_market_pairs: 141,
    date_added: "2017-08-22T00:00:00.000Z",
    tags: [
      "logistics",
      "data-provenance",
      "iot",
      "smart-contracts",
      "fenbushi-capital-portfolio",
    ],
    max_supply: 86712634466,
    circulating_supply: 64315576989,
    total_supply: 86712634466,
    platform: null,
    cmc_rank: 22,
    last_updated: "2021-08-01T04:55:04.000Z",
    quote: {
      USD: {
        price: 0.09269932839333,
        volume_24h: 644603274.8828814,
        percent_change_1h: 0.12750142,
        percent_change_24h: 6.62287019,
        percent_change_7d: 28.94647689,
        percent_change_30d: 10.49022378,
        percent_change_60d: -24.22745532,
        percent_change_90d: -57.36443,
        market_cap: 5962010792.10981,
        last_updated: "2021-08-01T04:55:04.000Z",
      },
    },
  },
  {
    id: 4943,
    name: "Dai",
    symbol: "DAI",
    slug: "multi-collateral-dai",
    num_market_pairs: 468,
    date_added: "2019-11-22T00:00:00.000Z",
    tags: [
      "defi",
      "stablecoin",
      "ethereum",
      "binance-smart-chain",
      "avalanche-ecosystem",
      "polygon-ecosystem",
    ],
    max_supply: null,
    circulating_supply: 5647122969.781855,
    total_supply: 5647123458.621,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0x6b175474e89094c44da98b954eedeac495271d0f",
    },
    cmc_rank: 23,
    last_updated: "2021-08-01T04:54:39.000Z",
    quote: {
      USD: {
        price: 1.00065393070074,
        volume_24h: 400628435.5622796,
        percent_change_1h: -0.06500956,
        percent_change_24h: -0.05970856,
        percent_change_7d: 0.01304697,
        percent_change_30d: 0.03846074,
        percent_change_60d: -0.06571241,
        percent_change_90d: -0.16029126,
        market_cap: 5650815796.862649,
        last_updated: "2021-08-01T04:54:39.000Z",
      },
    },
  },
  {
    id: 2280,
    name: "Filecoin",
    symbol: "FIL",
    slug: "filecoin",
    num_market_pairs: 147,
    date_added: "2017-12-13T00:00:00.000Z",
    tags: [
      "mineable",
      "distributed-computing",
      "filesharing",
      "storage",
      "polychain-capital-portfolio",
      "blockchain-capital-portfolio",
      "boostvc-portfolio",
      "dcg-portfolio",
      "hashkey-capital-portfolio",
      "a16z-portfolio",
      "winklevoss-capital",
      "pantera-capital-portfolio",
    ],
    max_supply: null,
    circulating_supply: 92876147,
    total_supply: 92876147,
    platform: null,
    cmc_rank: 24,
    last_updated: "2021-08-01T04:54:38.000Z",
    quote: {
      USD: {
        price: 56.73544815356459,
        volume_24h: 705408106.7851992,
        percent_change_1h: 0.20155343,
        percent_change_24h: 10.40703746,
        percent_change_7d: 15.41444473,
        percent_change_30d: 1.53605954,
        percent_change_60d: -17.97627123,
        percent_change_90d: -64.86908212,
        market_cap: 5269369822.821343,
        last_updated: "2021-08-01T04:54:38.000Z",
      },
    },
  },
  {
    id: 1958,
    name: "TRON",
    symbol: "TRX",
    slug: "tron",
    num_market_pairs: 512,
    date_added: "2017-09-13T00:00:00.000Z",
    tags: ["media", "payments"],
    max_supply: null,
    circulating_supply: 71659657369.49,
    total_supply: 100850743811.662,
    platform: null,
    cmc_rank: 25,
    last_updated: "2021-08-01T04:54:44.000Z",
    quote: {
      USD: {
        price: 0.06649735625264,
        volume_24h: 884001266.8918058,
        percent_change_1h: -0.12538367,
        percent_change_24h: 5.36957086,
        percent_change_7d: 16.32159565,
        percent_change_30d: 3.94840633,
        percent_change_60d: -13.0608327,
        percent_change_90d: -48.69379931,
        market_cap: 4765177765.041097,
        last_updated: "2021-08-01T04:54:44.000Z",
      },
    },
  },
  {
    id: 4172,
    name: "Terra",
    symbol: "LUNA",
    slug: "terra-luna",
    num_market_pairs: 73,
    date_added: "2019-07-26T00:00:00.000Z",
    tags: [
      "cosmos-ecosystem",
      "store-of-value",
      "defi",
      "payments",
      "stablecoin-algorithmically-stabilized",
      "coinbase-ventures-portfolio",
      "binance-labs-portfolio",
      "solana-ecosystem",
      "arrington-xrp-capital",
      "hashkey-capital-portfolio",
      "kinetic-capital",
      "huobi-capital",
      "pantera-capital-portfolio",
    ],
    max_supply: null,
    circulating_supply: 415693696.8713009,
    total_supply: 996396615.533853,
    platform: null,
    cmc_rank: 26,
    last_updated: "2021-08-01T04:55:07.000Z",
    quote: {
      USD: {
        price: 10.97043736634064,
        volume_24h: 287540391.7632949,
        percent_change_1h: -0.3923221,
        percent_change_24h: 0.21952175,
        percent_change_7d: 33.12732043,
        percent_change_30d: 91.46880993,
        percent_change_60d: 70.62186878,
        percent_change_90d: -40.16095647,
        market_cap: 4560341665.109199,
        last_updated: "2021-08-01T04:55:07.000Z",
      },
    },
  },
  {
    id: 7278,
    name: "Aave",
    symbol: "AAVE",
    slug: "aave",
    num_market_pairs: 256,
    date_added: "2020-10-02T00:00:00.000Z",
    tags: [
      "defi",
      "dao",
      "yield-farming",
      "three-arrows-capital-portfolio",
      "governance",
      "lending-borowing",
      "blockchain-capital-portfolio",
      "defiance-capital",
      "framework-ventures",
      "alameda-research-portfolio",
      "pantera-capital-portfolio",
      "parafi-capital",
      "polygon-ecosystem",
    ],
    max_supply: 16000000,
    circulating_supply: 12930484.16035275,
    total_supply: 16000000,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
    },
    cmc_rank: 27,
    last_updated: "2021-08-01T04:54:45.000Z",
    quote: {
      USD: {
        price: 339.1271651316256,
        volume_24h: 335009241.843591,
        percent_change_1h: 0.54434541,
        percent_change_24h: 6.35601699,
        percent_change_7d: 18.38892135,
        percent_change_30d: 53.99908475,
        percent_change_60d: -9.40185837,
        percent_change_90d: -33.30308493,
        market_cap: 4385078437.079816,
        last_updated: "2021-08-01T04:54:45.000Z",
      },
    },
  },
  {
    id: 328,
    name: "Monero",
    symbol: "XMR",
    slug: "monero",
    num_market_pairs: 169,
    date_added: "2014-05-21T00:00:00.000Z",
    tags: [
      "mineable",
      "pow",
      "medium-of-exchange",
      "privacy",
      "ringct",
      "boostvc-portfolio",
      "electric-capital-portfolio",
      "galaxy-digital-portfolio",
    ],
    max_supply: null,
    circulating_supply: 17964212.08118022,
    total_supply: 17964212.08118022,
    platform: null,
    cmc_rank: 28,
    last_updated: "2021-08-01T04:54:34.000Z",
    quote: {
      USD: {
        price: 243.20954904613453,
        volume_24h: 212428083.0986568,
        percent_change_1h: -1.18727984,
        percent_change_24h: 1.11810123,
        percent_change_7d: 16.96864083,
        percent_change_30d: 20.57594079,
        percent_change_60d: -8.62968744,
        percent_change_90d: -41.76313103,
        market_cap: 4369067919.232963,
        last_updated: "2021-08-01T04:54:34.000Z",
      },
    },
  },
  {
    id: 1765,
    name: "EOS",
    symbol: "EOS",
    slug: "eos",
    num_market_pairs: 437,
    date_added: "2017-07-01T00:00:00.000Z",
    tags: [
      "medium-of-exchange",
      "enterprise-solutions",
      "smart-contracts",
      "binance-chain",
      "fenbushi-capital-portfolio",
      "galaxy-digital-portfolio",
    ],
    max_supply: null,
    circulating_supply: 955669555.6609,
    total_supply: 1031753300.7076,
    platform: null,
    cmc_rank: 29,
    last_updated: "2021-08-01T04:55:09.000Z",
    quote: {
      USD: {
        price: 4.21841642279803,
        volume_24h: 1150376347.0636923,
        percent_change_1h: 0.25337147,
        percent_change_24h: 3.34313668,
        percent_change_7d: 15.49264753,
        percent_change_30d: 10.52971341,
        percent_change_60d: -32.74588459,
        percent_change_90d: -36.36740454,
        market_cap: 4031412148.3680363,
        last_updated: "2021-08-01T04:55:09.000Z",
      },
    },
  },
  {
    id: 7186,
    name: "PancakeSwap",
    symbol: "CAKE",
    slug: "pancakeswap",
    num_market_pairs: 109,
    date_added: "2020-09-25T00:00:00.000Z",
    tags: [
      "decentralized-exchange",
      "defi",
      "yield-farming",
      "binance-chain",
      "amm",
      "binance-smart-chain",
    ],
    max_supply: null,
    circulating_supply: 203677764.37016368,
    total_supply: 203677764.37016368,
    platform: {
      id: 1839,
      name: "Binance Smart Chain",
      symbol: "BNB",
      slug: "binance-coin",
      token_address: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    },
    cmc_rank: 30,
    last_updated: "2021-08-01T04:55:06.000Z",
    quote: {
      USD: {
        price: 16.74890573055031,
        volume_24h: 378156894.1532963,
        percent_change_1h: -1.04634517,
        percent_change_24h: 9.95364041,
        percent_change_7d: 21.32672281,
        percent_change_30d: 33.6438981,
        percent_change_60d: -6.4086515,
        percent_change_90d: -57.72263179,
        market_cap: 3411379674.84511,
        last_updated: "2021-08-01T04:55:06.000Z",
      },
    },
  },
  {
    id: 4195,
    name: "FTX Token",
    symbol: "FTT",
    slug: "ftx-token",
    num_market_pairs: 72,
    date_added: "2019-07-31T00:00:00.000Z",
    tags: [
      "marketplace",
      "centralized-exchange",
      "derivatives",
      "cms-holdings-portfolio",
      "kinetic-capital",
      "alameda-research-portfolio",
      "pantera-capital-portfolio",
      "exnetwork-capital-portfolio",
    ],
    max_supply: 352170015,
    circulating_supply: 94346957.523749,
    total_supply: 345219293.590976,
    platform: {
      id: 1839,
      name: "Binance Chain",
      symbol: "BNB",
      slug: "binance-coin",
      token_address: "FTT-F11",
    },
    cmc_rank: 31,
    last_updated: "2021-08-01T04:55:04.000Z",
    quote: {
      USD: {
        price: 36.133794119325,
        volume_24h: 57387063.62368204,
        percent_change_1h: -0.09328993,
        percent_change_24h: 5.16060018,
        percent_change_7d: 19.92170682,
        percent_change_30d: 41.01446235,
        percent_change_60d: 7.08805209,
        percent_change_90d: -35.66391011,
        market_cap: 3409113538.9478474,
        last_updated: "2021-08-01T04:55:04.000Z",
      },
    },
  },
  {
    id: 3635,
    name: "Crypto.com Coin",
    symbol: "CRO",
    slug: "crypto-com-coin",
    num_market_pairs: 108,
    date_added: "2018-12-14T00:00:00.000Z",
    tags: [
      "medium-of-exchange",
      "cosmos-ecosystem",
      "centralized-exchange",
      "mobile",
      "payments",
    ],
    max_supply: 30263013692,
    circulating_supply: 25263013692,
    total_supply: 30263013692,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
    },
    cmc_rank: 32,
    last_updated: "2021-08-01T04:54:40.000Z",
    quote: {
      USD: {
        price: 0.1321422077096,
        volume_24h: 25574519.64285138,
        percent_change_1h: -0.22018427,
        percent_change_24h: 3.72803197,
        percent_change_7d: 14.40417314,
        percent_change_30d: 19.11826413,
        percent_change_60d: 7.26781841,
        percent_change_90d: -30.72285636,
        market_cap: 3338310402.658733,
        last_updated: "2021-08-01T04:54:40.000Z",
      },
    },
  },
  {
    id: 1376,
    name: "Neo",
    symbol: "NEO",
    slug: "neo",
    num_market_pairs: 243,
    date_added: "2016-09-08T00:00:00.000Z",
    tags: ["platform", "enterprise-solutions", "smart-contracts"],
    max_supply: 100000000,
    circulating_supply: 70538831,
    total_supply: 100000000,
    platform: null,
    cmc_rank: 33,
    last_updated: "2021-08-01T04:55:06.000Z",
    quote: {
      USD: {
        price: 45.20325138287754,
        volume_24h: 1086425627.0842798,
        percent_change_1h: -0.91695769,
        percent_change_24h: 3.75634901,
        percent_change_7d: 47.36214759,
        percent_change_30d: 33.23129014,
        percent_change_60d: -16.40858055,
        percent_change_90d: -58.91251132,
        market_cap: 3188584509.947315,
        last_updated: "2021-08-01T04:55:06.000Z",
      },
    },
  },
  {
    id: 6719,
    name: "The Graph",
    symbol: "GRT",
    slug: "the-graph",
    num_market_pairs: 144,
    date_added: "2020-12-17T00:00:00.000Z",
    tags: [
      "ai-big-data",
      "enterprise-solutions",
      "defi",
      "binance-smart-chain",
      "coinbase-ventures-portfolio",
      "solana-ecosystem",
      "analytics",
      "coinfund-portfolio",
      "dcg-portfolio",
      "fabric-ventures-portfolio",
      "framework-ventures",
      "ledgerprime-portfolio",
      "multicoin-capital-portfolio",
      "parafi-capital",
      "polygon-ecosystem",
    ],
    max_supply: 10057044431,
    circulating_supply: 4715735200,
    total_supply: 10000000000,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0xc944e90c64b2c07662a292be6244bdf05cda44a7",
    },
    cmc_rank: 34,
    last_updated: "2021-08-01T04:55:08.000Z",
    quote: {
      USD: {
        price: 0.66243394806116,
        volume_24h: 134787456.68946025,
        percent_change_1h: 1.29473427,
        percent_change_24h: 10.1035408,
        percent_change_7d: 21.12960106,
        percent_change_30d: 24.57044554,
        percent_change_60d: -13.18767502,
        percent_change_90d: -59.90364659,
        market_cap: 3123863086.5469837,
        last_updated: "2021-08-01T04:55:08.000Z",
      },
    },
  },
  {
    id: 4023,
    name: "Bitcoin BEP2",
    symbol: "BTCB",
    slug: "bitcoin-bep2",
    num_market_pairs: 47,
    date_added: "2019-06-18T00:00:00.000Z",
    tags: ["binance-chain"],
    max_supply: null,
    circulating_supply: 73112.22733745,
    total_supply: 80501,
    platform: {
      id: 1839,
      name: "Binance Chain",
      symbol: "BNB",
      slug: "binance-coin",
      token_address: "BTCB-1DE",
    },
    cmc_rank: 35,
    last_updated: "2021-08-01T04:54:47.000Z",
    quote: {
      USD: {
        price: 42228.24303398142,
        volume_24h: 37079947.03041671,
        percent_change_1h: -0.32122357,
        percent_change_24h: 0.93131394,
        percent_change_7d: 22.58013299,
        percent_change_30d: 27.63291819,
        percent_change_60d: 15.39558773,
        percent_change_90d: -27.03689969,
        market_cap: 3087400904.7615385,
        last_updated: "2021-08-01T04:54:47.000Z",
      },
    },
  },
  {
    id: 1518,
    name: "Maker",
    symbol: "MKR",
    slug: "maker",
    num_market_pairs: 224,
    date_added: "2017-01-29T00:00:00.000Z",
    tags: [
      "store-of-value",
      "defi",
      "dao",
      "polychain-capital-portfolio",
      "governance",
      "lending-borowing",
      "dragonfly-capital-portfolio",
      "electric-capital-portfolio",
      "a16z-portfolio",
      "1confirmation-portfolio",
      "placeholder-ventures-portfolio",
      "pantera-capital-portfolio",
      "paradigm-xzy-screener",
    ],
    max_supply: 1005577,
    circulating_supply: 991328.38195493,
    total_supply: 991328.38195493,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
    },
    cmc_rank: 36,
    last_updated: "2021-08-01T04:54:48.000Z",
    quote: {
      USD: {
        price: 3056.679180096858,
        volume_24h: 109179351.4554141,
        percent_change_1h: 0.59576053,
        percent_change_24h: 5.30545443,
        percent_change_7d: 20.78161271,
        percent_change_30d: 24.17966256,
        percent_change_60d: -14.16232779,
        percent_change_90d: -44.85941194,
        market_cap: 3030172825.7607403,
        last_updated: "2021-08-01T04:54:48.000Z",
      },
    },
  },
  {
    id: 6945,
    name: "Amp",
    symbol: "AMP",
    slug: "amp",
    num_market_pairs: 47,
    date_added: "2020-09-08T00:00:00.000Z",
    tags: [],
    max_supply: 92547638199,
    circulating_supply: 42227702186,
    total_supply: 99213408535,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0xff20817765cb7f73d4bde2e66e067e58d11095c2",
    },
    cmc_rank: 37,
    last_updated: "2021-08-01T04:54:45.000Z",
    quote: {
      USD: {
        price: 0.07134403914806,
        volume_24h: 45981574.96392337,
        percent_change_1h: -0.52094793,
        percent_change_24h: -1.78954717,
        percent_change_7d: 42.50759857,
        percent_change_30d: 27.71943017,
        percent_change_60d: 90.2442332,
        percent_change_90d: 21.65600696,
        market_cap: 3012694837.890603,
        last_updated: "2021-08-01T04:54:45.000Z",
      },
    },
  },
  {
    id: 3957,
    name: "UNUS SED LEO",
    symbol: "LEO",
    slug: "unus-sed-leo",
    num_market_pairs: 17,
    date_added: "2019-05-21T00:00:00.000Z",
    tags: [
      "marketplace",
      "centralized-exchange",
      "discount-token",
      "payments",
      "arrington-xrp-capital",
      "kinetic-capital",
      "alameda-research-portfolio",
    ],
    max_supply: null,
    circulating_supply: 953954130,
    total_supply: 985239504,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3",
    },
    cmc_rank: 38,
    last_updated: "2021-08-01T04:54:49.000Z",
    quote: {
      USD: {
        price: 3.07494966259062,
        volume_24h: 2087590.84776174,
        percent_change_1h: -0.68929441,
        percent_change_24h: 2.04930509,
        percent_change_7d: 6.30947647,
        percent_change_30d: 28.84441105,
        percent_change_60d: 14.92374853,
        percent_change_90d: 24.05621058,
        market_cap: 2933360930.1704283,
        last_updated: "2021-08-01T04:54:49.000Z",
      },
    },
  },
  {
    id: 3794,
    name: "Cosmos",
    symbol: "ATOM",
    slug: "cosmos",
    num_market_pairs: 181,
    date_added: "2019-03-14T00:00:00.000Z",
    tags: [
      "platform",
      "cosmos-ecosystem",
      "content-creation",
      "interoperability",
      "binance-chain",
      "polychain-capital-portfolio",
      "dragonfly-capital-portfolio",
      "hashkey-capital-portfolio",
      "1confirmation-portfolio",
      "paradigm-xzy-screener",
      "exnetwork-capital-portfolio",
    ],
    max_supply: null,
    circulating_supply: 219017699.932601,
    total_supply: 276802302.932601,
    platform: null,
    cmc_rank: 39,
    last_updated: "2021-08-01T04:54:38.000Z",
    quote: {
      USD: {
        price: 13.02701224837346,
        volume_24h: 241034224.13782337,
        percent_change_1h: -0.34862944,
        percent_change_24h: 8.06580585,
        percent_change_7d: 14.51597266,
        percent_change_30d: 20.42924281,
        percent_change_60d: -7.46096706,
        percent_change_90d: -44.36308484,
        market_cap: 2853146259.6325765,
        last_updated: "2021-08-01T04:54:38.000Z",
      },
    },
  },
  {
    id: 1720,
    name: "IOTA",
    symbol: "MIOTA",
    slug: "iota",
    num_market_pairs: 57,
    date_added: "2017-06-13T00:00:00.000Z",
    tags: ["dag", "medium-of-exchange", "iot", "sharing-economy"],
    max_supply: 2779530283,
    circulating_supply: 2779530283,
    total_supply: 2779530283,
    platform: null,
    cmc_rank: 40,
    last_updated: "2021-08-01T04:55:09.000Z",
    quote: {
      USD: {
        price: 0.99459412689718,
        volume_24h: 253252825.26746106,
        percent_change_1h: 0.99973807,
        percent_change_24h: 18.20413392,
        percent_change_7d: 31.38785277,
        percent_change_30d: 28.82488276,
        percent_change_60d: -13.667782,
        percent_change_90d: -53.16744787,
        market_cap: 2764504495.0046563,
        last_updated: "2021-08-01T04:55:09.000Z",
      },
    },
  },
  {
    id: 3602,
    name: "Bitcoin SV",
    symbol: "BSV",
    slug: "bitcoin-sv",
    num_market_pairs: 188,
    date_added: "2018-11-09T00:00:00.000Z",
    tags: [
      "mineable",
      "pow",
      "sha-256",
      "medium-of-exchange",
      "store-of-value",
      "state-channels",
    ],
    max_supply: 21000000,
    circulating_supply: 18802501.64423905,
    total_supply: 18802501.64423905,
    platform: null,
    cmc_rank: 41,
    last_updated: "2021-08-01T04:55:07.000Z",
    quote: {
      USD: {
        price: 146.31073375431075,
        volume_24h: 283443900.6412754,
        percent_change_1h: 0.04419634,
        percent_change_24h: 2.95177362,
        percent_change_7d: 14.77726723,
        percent_change_30d: 3.82233504,
        percent_change_60d: -16.45596414,
        percent_change_90d: -56.70290577,
        market_cap: 2751007811.9852495,
        last_updated: "2021-08-01T04:55:07.000Z",
      },
    },
  },
  {
    id: 4030,
    name: "Algorand",
    symbol: "ALGO",
    slug: "algorand",
    num_market_pairs: 138,
    date_added: "2019-06-20T00:00:00.000Z",
    tags: [
      "pos",
      "platform",
      "research",
      "smart-contracts",
      "arrington-xrp-capital",
      "kinetic-capital",
      "usv-portfolio",
      "multicoin-capital-portfolio",
      "exnetwork-capital-portfolio",
    ],
    max_supply: 10000000000,
    circulating_supply: 3169312105.785312,
    total_supply: 5621579118.168647,
    platform: null,
    cmc_rank: 42,
    last_updated: "2021-08-01T04:54:57.000Z",
    quote: {
      USD: {
        price: 0.85551344394287,
        volume_24h: 74950947.31807226,
        percent_change_1h: -0.26370125,
        percent_change_24h: 2.77800855,
        percent_change_7d: 4.63890963,
        percent_change_30d: 4.56079456,
        percent_change_60d: -5.54342568,
        percent_change_90d: -39.64918351,
        market_cap: 2711389114.550222,
        last_updated: "2021-08-01T04:54:57.000Z",
      },
    },
  },
  {
    id: 2011,
    name: "Tezos",
    symbol: "XTZ",
    slug: "tezos",
    num_market_pairs: 169,
    date_added: "2017-10-06T00:00:00.000Z",
    tags: [
      "pos",
      "platform",
      "enterprise-solutions",
      "collectibles-nfts",
      "smart-contracts",
      "polychain-capital-portfolio",
      "boostvc-portfolio",
      "winklevoss-capital",
    ],
    max_supply: null,
    circulating_supply: 854448906.713368,
    total_supply: 884392844.598834,
    platform: null,
    cmc_rank: 43,
    last_updated: "2021-08-01T04:54:47.000Z",
    quote: {
      USD: {
        price: 3.1551553834896,
        volume_24h: 119809402.54157135,
        percent_change_1h: -0.4090862,
        percent_change_24h: 5.3441793,
        percent_change_7d: 11.13434183,
        percent_change_30d: 11.8000193,
        percent_change_60d: -14.72393069,
        percent_change_90d: -44.74627061,
        market_cap: 2695919067.933486,
        last_updated: "2021-08-01T04:54:47.000Z",
      },
    },
  },
  {
    id: 4256,
    name: "Klaytn",
    symbol: "KLAY",
    slug: "klaytn",
    num_market_pairs: 52,
    date_added: "2020-03-30T00:00:00.000Z",
    tags: ["platform", "enterprise-solutions"],
    max_supply: null,
    circulating_supply: 2491188666.8,
    total_supply: 10634257244.8,
    platform: null,
    cmc_rank: 44,
    last_updated: "2021-08-01T04:54:37.000Z",
    quote: {
      USD: {
        price: 1.07000479106572,
        volume_24h: 100846582.42558762,
        percent_change_1h: -0.92611579,
        percent_change_24h: 3.01436333,
        percent_change_7d: 8.28900629,
        percent_change_30d: 11.07073729,
        percent_change_60d: -22.1497146,
        percent_change_90d: -56.23051097,
        market_cap: 2665583808.924624,
        last_updated: "2021-08-01T04:54:37.000Z",
      },
    },
  },
  {
    id: 5994,
    name: "SHIBA INU",
    symbol: "SHIB",
    slug: "shiba-inu",
    num_market_pairs: 117,
    date_added: "2020-08-01T00:00:00.000Z",
    tags: ["memes", "doggone-doggerel"],
    max_supply: null,
    circulating_supply: 394796000000000,
    total_supply: 1000000000000000,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
    },
    cmc_rank: 45,
    last_updated: "2021-08-01T04:54:43.000Z",
    quote: {
      USD: {
        price: 0.00000665030763,
        volume_24h: 244087309.3180968,
        percent_change_1h: 2.31290284,
        percent_change_24h: 6.52201607,
        percent_change_7d: 3.88032782,
        percent_change_30d: -18.73771445,
        percent_change_60d: -24.34433477,
        percent_change_90d: 284.48155709,
        market_cap: 2625514851.0934796,
        last_updated: "2021-08-01T04:54:43.000Z",
      },
    },
  },
  {
    id: 6783,
    name: "Axie Infinity",
    symbol: "AXS",
    slug: "axie-infinity",
    num_market_pairs: 92,
    date_added: "2020-08-31T00:00:00.000Z",
    tags: [
      "collectibles-nfts",
      "gaming",
      "binance-launchpad",
      "metaverse",
      "defiance-capital",
      "play-to-earn",
    ],
    max_supply: 270000000,
    circulating_supply: 60907500,
    total_supply: 270000000,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
    },
    cmc_rank: 46,
    last_updated: "2021-08-01T04:54:47.000Z",
    quote: {
      USD: {
        price: 41.48333852095414,
        volume_24h: 912675611.3338295,
        percent_change_1h: 0.75395958,
        percent_change_24h: -7.8659595,
        percent_change_7d: 1.65207082,
        percent_change_30d: 587.31980685,
        percent_change_60d: 807.51092085,
        percent_change_90d: 346.18442793,
        market_cap: 2526646440.9650145,
        last_updated: "2021-08-01T04:54:47.000Z",
      },
    },
  },
  {
    id: 5805,
    name: "Avalanche",
    symbol: "AVAX",
    slug: "avalanche",
    num_market_pairs: 70,
    date_added: "2020-07-13T00:00:00.000Z",
    tags: [
      "defi",
      "smart-contracts",
      "polychain-capital-portfolio",
      "avalanche-ecosystem",
      "cms-holdings-portfolio",
      "dragonfly-capital-portfolio",
    ],
    max_supply: 720000000,
    circulating_supply: 173407302.87262756,
    total_supply: 386911241.2226276,
    platform: null,
    cmc_rank: 47,
    last_updated: "2021-08-01T04:55:08.000Z",
    quote: {
      USD: {
        price: 13.92586203061968,
        volume_24h: 73849976.19932428,
        percent_change_1h: 1.12710497,
        percent_change_24h: 8.14910518,
        percent_change_7d: 24.88914886,
        percent_change_30d: 28.55636349,
        percent_change_60d: -21.21922716,
        percent_change_90d: -57.585769,
        market_cap: 2414846174.906091,
        last_updated: "2021-08-01T04:55:08.000Z",
      },
    },
  },
  {
    id: 5692,
    name: "Compound",
    symbol: "COMP",
    slug: "compound",
    num_market_pairs: 221,
    date_added: "2020-06-16T00:00:00.000Z",
    tags: [
      "defi",
      "dao",
      "yield-farming",
      "coinbase-ventures-portfolio",
      "three-arrows-capital-portfolio",
      "polychain-capital-portfolio",
      "lending-borowing",
      "dragonfly-capital-portfolio",
      "alameda-research-portfolio",
      "a16z-portfolio",
      "pantera-capital-portfolio",
      "paradigm-xzy-screener",
    ],
    max_supply: 10000000,
    circulating_supply: 5386471.1646944,
    total_supply: 10000000,
    platform: {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      token_address: "0xc00e94cb662c3520282e6f5717214004a7f26888",
    },
    cmc_rank: 48,
    last_updated: "2021-08-01T04:54:45.000Z",
    quote: {
      USD: {
        price: 413.4338932820838,
        volume_24h: 175894245.08953723,
        percent_change_1h: -0.49841117,
        percent_change_24h: 3.3049819,
        percent_change_7d: 2.24019541,
        percent_change_30d: 29.70103699,
        percent_change_60d: -2.72211488,
        percent_change_90d: -49.32033161,
        market_cap: 2226949744.671286,
        last_updated: "2021-08-01T04:54:45.000Z",
      },
    },
  },
  {
    id: 1168,
    name: "Decred",
    symbol: "DCR",
    slug: "decred",
    num_market_pairs: 54,
    date_added: "2016-02-10T00:00:00.000Z",
    tags: [
      "mineable",
      "hybrid-pow-pos",
      "blake256",
      "medium-of-exchange",
      "store-of-value",
      "privacy",
      "dao",
      "placeholder-ventures-portfolio",
    ],
    max_supply: 21000000,
    circulating_supply: 13172845.24114286,
    total_supply: 13172845.24114286,
    platform: null,
    cmc_rank: 49,
    last_updated: "2021-08-01T04:55:05.000Z",
    quote: {
      USD: {
        price: 157.01598269749215,
        volume_24h: 19557547.53160716,
        percent_change_1h: -0.97369021,
        percent_change_24h: 4.638183,
        percent_change_7d: 22.53252978,
        percent_change_30d: 17.80227934,
        percent_change_60d: -1.97828862,
        percent_change_90d: -23.79662561,
        market_cap: 2068347240.4600291,
        last_updated: "2021-08-01T04:55:05.000Z",
      },
    },
  },
  {
    id: 7129,
    name: "TerraUSD",
    symbol: "UST",
    slug: "terrausd",
    num_market_pairs: 88,
    date_added: "2020-09-21T00:00:00.000Z",
    tags: ["stablecoin"],
    max_supply: null,
    circulating_supply: 2039074021.543542,
    total_supply: 2039074021.543542,
    platform: null,
    cmc_rank: 50,
    last_updated: "2021-08-01T04:54:33.000Z",
    quote: {
      USD: {
        price: 1.00111918205559,
        volume_24h: 41902826.72098692,
        percent_change_1h: 0.01946255,
        percent_change_24h: -0.03323831,
        percent_change_7d: -0.01264847,
        percent_change_30d: 0.08858687,
        percent_change_60d: 0.15017063,
        percent_change_90d: 0.07022998,
        market_cap: 2041356116.5984733,
        last_updated: "2021-08-01T04:54:33.000Z",
      },
    },
  },
];
