import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";

interface Props {
  message: string;
  redirectedPath?: string;
}
const Error = ({ message, redirectedPath }: Props) => {
  const history = useHistory();
  const routeHandler = () => {
    redirectedPath ? history.push(redirectedPath) : history.push("/");
  };
  return (
    <div>
      <h2>{message}</h2>
      <Button onClick={routeHandler}>Go back</Button>
    </div>
  );
};

export default Error;
