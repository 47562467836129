import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { CryptoCurrency } from "../../../model/Cryptocurrency";
import { useCryptoContext } from "../../../store/fav-context";
import { FilterData, SortData } from "./Table";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

interface Props {
  filteredData?: FilterData;
  onSort: (v: SortData) => void;
  sort: SortData;
}

type BaseColumn = {
  content?: (item: CryptoCurrency) => JSX.Element;
};

export interface DataColumn extends BaseColumn {
  path: keyof CryptoCurrency;
  label: string;
}

export interface MetadataColumn extends BaseColumn {
  key: string;
}

export type Column = DataColumn | MetadataColumn;

const CoinsTable = ({ filteredData, onSort, sort }: Props) => {
  const [sortedData, setSortedData] = useState(filteredData?.pagedData);
  const { onAddCrypto, currencies, onRemoveCrypto } = useCryptoContext();
  useEffect(() => {
    setSortedData(filteredData?.pagedData);
  }, [filteredData]);

  const tableColumns: Column[] = [
    {
      key: "fav",
      content: (cry: CryptoCurrency) =>
        !currencies.includes(cry) ? (
          <td onClick={() => onAddCrypto(cry)} key={"fav"}>
            <Icon name="check circle outline" />
          </td>
        ) : (
          <td onClick={() => onRemoveCrypto(cry.id)} key={"fav"}>
            <Icon name="check circle" />
          </td>
        ),
    },
    { label: "Id", path: "id" },
    {
      label: "Name",
      path: "name",
      content: (cry: CryptoCurrency) => (
        <Link to={`/coin/${cry.slug}`}>{cry.name}</Link>
      ),
    },
    { label: "Symbol", path: "symbol" },
    { label: "Total Supply", path: "total_supply" },
    { label: "Rank", path: "cmc_rank" },
    // { label: "Price", path: "quote.USD.price" },
  ];

  return (
    <table className="ui celled table">
      <TableHeader onSort={onSort} sort={sort} tableColumn={tableColumns} />
      <TableBody data={sortedData} tableColumn={tableColumns} />
    </table>
  );
};

export default CoinsTable;
