import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import useHttp from "../../../hooks/use-http";
import { User } from "../../../model/User";
import Loading from "../../UI/Loading/Loading";

const Profile = () => {
  const { isLoading, sendGetRequest, sendPostRequest } = useHttp();
  const [userInfo, setUserInfo] = useState<User>({} as User);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const transformUserData = (userData: any[]) => {
      const data = userData;

      let desiredUser: User = {
        objectId: data[data.length - 1].objectId,
        username: data[data.length - 1].username,
      };
      setUserInfo(desiredUser);
    };

    sendGetRequest("/_User", transformUserData);
  }, [sendGetRequest]);

  const newUsername = (newUser: string, newUserData: any) => {
    let objectId = newUserData.objectId;
    console.log("Profile", newUserData);
    setUserInfo({ username: newUser, objectId: objectId });
  };

  const formHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const value = inputRef.current?.value;
    if (value!.trim().length > 0) {
      sendPostRequest(
        "/_User",
        { username: value, password: "1234" },
        newUsername.bind(null, value!)
      );
    } else return;
  };

  if (isLoading) <Loading />;
  if (userInfo === undefined) <span>Not any users avaiable here!</span>;

  return (
    <>
      <form onSubmit={formHandler}>
        <h1>{userInfo.username}</h1>
        <span>{userInfo.objectId}</span>
        <input type="text" ref={inputRef} />
        <button type="submit">get a new Username</button>
      </form>
    </>
  );
};

export default Profile;
