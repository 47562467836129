import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DUMMY_DATA } from "../../../data/Dummy_Data";
import { CryptoCurrency } from "../../../model/Cryptocurrency";
import Error from "../Error/Error";

const CoinDetail = () => {
  const { slug } = useParams<{ slug: string }>();
  const [coin, setCoin] = useState<CryptoCurrency | undefined>(undefined);

  useEffect(() => {
    setCoin(DUMMY_DATA.find((x) => x.slug === slug));
  }, [slug]);

  if (!coin) {
    return (
      <Error
        message={`Oooooooops! ----${slug}---- Was Not Defiend`}
        redirectedPath="/coin"
      />
    );
  }

  return (
    <>
      <h1>{coin?.name}</h1>
      <div>{coin?.date_added}</div>
    </>
  );
};

export default CoinDetail;
