import { Divider } from "semantic-ui-react";

interface Props {
  itemsSize: number;
  pageSize: number;
  currentPage: number;
  onChangePage: (id: number) => void;
  onPageSizeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Pagination = ({
  itemsSize,
  pageSize,
  currentPage,
  onChangePage: onClick,
  onPageSizeChange,
}: Props) => {
  const getPaginatedArray = () => {
    const pagesCount = Math.ceil(itemsSize / pageSize);
    if (pagesCount === 1) return null;

    const pages = [];
    for (let i = 1; i <= pagesCount; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <>
      <div className="ui labeled input" style={{ width: 55 }}>
        <input
          type="number"
          name="pageSize"
          value={pageSize}
          onChange={onPageSizeChange}
        />
      </div>

      <div className="ui pagination pointing secondary menu">
        {getPaginatedArray()?.map((page, i) => {
          return (
            <li
              key={i}
              onClick={() => onClick(page)}
              className={`item ${page === currentPage ? "active" : ""}`}
              style={{ cursor: "pointer" }}
            >
              {page}
            </li>
          );
        })}
      </div>
    </>
  );
};

export default Pagination;
