import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import * as configJson from "../config.json";

//Base URL Config
axios.defaults.baseURL = configJson.API_URL;

//Interceptors Request Config
axios.interceptors.request.use((config) => {
  config.headers["X-Parse-REST-API-Key"] = configJson["X-Parse-REST-API-Key"];
  config.headers["X-Parse-Application-Id"] =
    configJson["X-Parse-Application-Id"];
  return config;
}, undefined);

//Interceptors Response Config
axios.interceptors.response.use(undefined, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  expectedError && toast(error.response.data.error);

  if (!expectedError) {
    toast.error("An error has occured!");
  }

  return Promise.reject(error);
});


const responseBody = (response: AxiosResponse) => response.data;

//General Requests
export const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, data?: {}) =>
    axios.post<T>(url, data).then(responseBody),
};

export default request;
