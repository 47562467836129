import { CryptoCurrency } from "../../../model/Cryptocurrency";
import { Column, DataColumn } from "./CoinsTable";

interface Props {
  data?: CryptoCurrency[];
  tableColumn: Column[];
}

const TableBody = ({ data, tableColumn }: Props) => {
  return (
    <tbody>
      {data?.map((cry) => {
        return (
          <tr key={cry.id}>
            {tableColumn.map((col) => {
              return "key" in col ? (
                col.content?.(cry)
              ) : (
                <td key={col.path}>
                  {col.content?.(cry) || cry[(col as DataColumn).path]}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
