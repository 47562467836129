import { Dropdown, DropdownProps } from "semantic-ui-react";
import { TagObj } from "../Table/Table";

interface Props {
  tags?: TagObj[];
  currentTag?: string;
  onSelectedTag: (item: string) => void;
}
const Tags = ({ tags, currentTag, onSelectedTag }: Props) => {
  const changeHandler = (
    event: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    onSelectedTag(value as string);
  };

  return (
    <div>
      <Dropdown
        placeholder="search a Tag"
        search
        clearable
        selection
        name="tags"
        options={tags}
        value={currentTag}
        onChange={(event, data) => changeHandler(event, data)}
      />
    </div>
  );
};

export default Tags;
