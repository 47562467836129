import { useCallback, useState } from "react";
import request from "../service/agent";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);

  const sendGetRequest = useCallback(
    (url: string, applyData: (argData: any) => void) => {
      setIsLoading(true);
      request.get(url).then((res) => {
        applyData(res.results);
      });
      setIsLoading(false);
    },
    []
  );

  const sendPostRequest = useCallback(
    (url: string, data: any, applyData: (argData: any) => void) => {
      setIsLoading(true);
      request.post(url, data).then((res) => {
        applyData(res);
      });
      setIsLoading(false);
    },
    []
  );

  return {
    sendGetRequest,
    sendPostRequest,
    isLoading,
  };
};

export default useHttp;
