import styled from "styled-components";

const BackDropStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
`;

interface Props {
  onClose: () => void;
}
const Backdrop = ({ onClose }: Props) => {
  return <BackDropStyled onClick={onClose}></BackDropStyled>;
};

export default Backdrop;
