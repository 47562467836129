import { ChangeEvent, FormEvent, useEffect, useRef } from "react";
import { useState } from "react";
import { Container } from "semantic-ui-react";
import { DUMMY_DATA } from "../../../data/Dummy_Data";
import Tags from "../Filter/Tags";
import Pagination from "../Pagination/Pagination";
import { CryptoCurrency } from "../../../model/Cryptocurrency";
import CoinsTable from "./CoinsTable";
import Loading from "../Loading/Loading";
import Search from "../Filter/Search";

export type TagObj = {
  key: string;
  text: string;
  value: string;
};

export type FilterData = {
  pagedData?: CryptoCurrency[];
  Listlength: number;
};

export type SortFilterColumn = keyof CryptoCurrency;

export type SortData = {
  path: keyof CryptoCurrency;
  order: "ASC" | "DESC";
};

const Table = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [cryptoList, setCryptoList] = useState<CryptoCurrency[]>(
    [] as CryptoCurrency[]
  );
  const [filteredData, setFilteredData] = useState<FilterData | undefined>(
    undefined
  );
  const [tags, setTags] = useState<TagObj[]>();
  const [selectedTag, setSelectedTag] = useState("");
  const [sort, setSort] = useState<SortData>({ order: "ASC", path: "name" });
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [searchText, setSearchText] = useState("");
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // async function fetchData() {
    //   setIsLoading(true);
    //    const resp = await coinRequest.getCoinAsset;
    // }
    //fetchData();
    setCryptoList(DUMMY_DATA);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    let filteredList =
      selectedTag !== ""
        ? cryptoList.filter((item) => item?.tags.includes(selectedTag))
        : cryptoList;

    filteredList =
      searchText !== ""
        ? filteredList.filter((item) =>
            item.name.toLowerCase().includes(searchText)
          )
        : filteredList;

    filteredList = filteredList.sort((coin1, coin2) => {
      return coin1[sort.path]! > coin2[sort.path]!
        ? sort.order === "ASC"
          ? 1
          : -1
        : coin1[sort.path]! < coin2[sort.path]!
        ? sort.order === "ASC"
          ? -1
          : 1
        : 0;
      // return 1;
    });

    const first = pageSize * (currentPage - 1);
    const last = first + pageSize;
    const pagedList = filteredList.slice(first, last);
    setFilteredData({ Listlength: filteredList.length, pagedData: pagedList });
  }, [currentPage, cryptoList, selectedTag, sort, pageSize, searchText]);

  useEffect(() => {
    const listOfTags: string[] = cryptoList.reduce((cum, cur) => {
      cum.push(...cur!.tags);
      return [...new Set(cum)];
    }, [] as string[]);

    const listOfTagsObj: TagObj[] = [];
    listOfTags.map((tag) => {
      return listOfTagsObj.push({
        key: tag,
        text: tag,
        value: tag,
      });
    });
    setTags(listOfTagsObj);
    setCurrentPage(1);
  }, [cryptoList, selectedTag]);

  const changePageHandler = (id: number) => {
    setCurrentPage(id);
  };

  const changePageSizeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPageSize(+e.target.value);
  };

  const searchHandler = (e: FormEvent) => {
    e.preventDefault();
    if (searchRef.current && searchRef.current.value.length > 0) {
      const value = searchRef.current.value.trim().toLowerCase();
      setSearchText(value);
    }
  };

  const clearSearchHandler = () => {
    setSearchText("");
    searchRef.current!.value = "";
    searchRef.current?.focus();
  };

  if (cryptoList.length === 0) <span>There is nothing to show</span>; //TODO: create a component for empties
  if (isLoading) <Loading />;
  return (
    <Container>
      <div className="ui grid">
        <div className="left floated five wide column">
          <Tags
            tags={tags}
            onSelectedTag={(item) => setSelectedTag(item)}
            currentTag={selectedTag}
          />
        </div>

        <div className="right floated five wide column">
          <Search
            ref={searchRef}
            clearSearchHandler={clearSearchHandler}
            searchHandler={searchHandler}
          />
        </div>
      </div>
      <CoinsTable
        filteredData={filteredData}
        sort={sort}
        onSort={(v) => setSort(v)}
      />
      <Pagination
        currentPage={currentPage}
        itemsSize={filteredData?.Listlength!}
        pageSize={pageSize}
        onChangePage={changePageHandler}
        onPageSizeChange={changePageSizeHandler}
      />
    </Container>
  );
};

export default Table;
