import { useForm, ValidationError } from "@formspree/react";
import { Form, Button, Container } from "semantic-ui-react";

const Contact = () => {
  const [state, handleSubmit] = useForm("xzbyjdqb");

  if (state.succeeded) {
    return <p>Thanks for your message!</p>;
  }
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <label htmlFor="email">Email Address</label>
          <input id="email" type="email" name="email" />
        </Form.Field>
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <Form.Field
          control="textarea"
          id="message"
          name="message"
          label="Message"
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        <Button type="submit" disabled={state.submitting}>
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default Contact;
