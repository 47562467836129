import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import CoinDetail from "./components/Pages/Coins/CoinDetail";

import Contact from "./components/Pages/Contact/Contact";
import Error from "./components/Pages/Error/Error";
import Profile from "./components/Pages/Profile/Profile";
import Modal from "./components/UI/Modal/Modal";
import Navbar from "./components/UI/Navbar/Navbar";
import Table from "./components/UI/Table/Table";
import { FavContextProvider } from "./store/fav-context";
import { ToastContainer } from "react-toastify";
import Login from "./components/Pages/Profile/Login";
import Register from "./components/Pages/Profile/Register";

function App() {
  const [isShowModal, setIsShowModal] = useState(false);
  return (
    <FavContextProvider>
      <div className="App">
        {isShowModal && <Modal onClose={() => setIsShowModal(false)} />}
        <Navbar onShowModal={() => setIsShowModal(true)} />
        <ToastContainer />
        <Switch>
          <Route path="/register" component={Register} />
          <Route path="/profile" component={Profile} />
          <Route path="/login" component={Login} />
          <Route path="/contact" component={Contact} />
          <Route path="/coin/:slug" component={CoinDetail} />
          <Route path="/coin" component={Table} />
          <Route path="/error">
            <Error message="404 - not found" />
          </Route>
          <Route path="/" exact component={Table} />
          <Redirect to="/error" />
        </Switch>
      </div>
    </FavContextProvider>
  );
}

export default App;
