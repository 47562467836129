import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Icon } from "semantic-ui-react";
import { CryptoCurrency } from "../../../model/Cryptocurrency";
import { Column, DataColumn, MetadataColumn } from "./CoinsTable";
import { SortData, SortFilterColumn } from "./Table";

interface Props {
  onSort: (v: SortData) => void;
  sort: SortData;
  tableColumn: Column[];
}

const TableHeader = ({ onSort, sort, tableColumn }: Props) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sortValue = queryParams.get("sort");
    const col = queryParams.get("col");
    sortValue &&
      onSort({
        order: sortValue === "asc" ? "ASC" : "DESC",
        path: col as keyof CryptoCurrency,
      });
  }, [location, onSort]);

  const sortHandler = (col: SortFilterColumn) => {
    const order =
      sort.path === col ? (sort.order === "ASC" ? "DESC" : "ASC") : "ASC";
    onSort({ order: order, path: col });

    history.push(
      `${location.pathname}?col=${col}&sort=${order.toLocaleLowerCase()}`
    );
  };

  const renderSortIcon = (col: Column) => {
    if ((col as DataColumn).path !== sort.path || (col as MetadataColumn).key)
      return null;

    return sort.order === "ASC" ? (
      <Icon name="sort up" />
    ) : (
      <Icon name="sort down" />
    );
  };

  return (
    <thead>
      <tr>
        {tableColumn.map((column) => (
          <th
            key={"key" in column ? column.key : column.path}
            onClick={() => sortHandler((column as DataColumn).path)}
          >
            {(column as DataColumn).label || (column as MetadataColumn).key}
            {renderSortIcon(column)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
