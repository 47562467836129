import React from "react";
import { useReducer } from "react";
import { useContext } from "react";
import { CryptoCurrency } from "../model/Cryptocurrency";

interface FavCryptoCtx {
  currencies: CryptoCurrency[];
  onAddCrypto: (crypto: CryptoCurrency) => void;
  onRemoveCrypto: (id: number) => void;
}

const FavContext = React.createContext<FavCryptoCtx>({} as FavCryptoCtx);

interface FavCrypto {
  currencies: CryptoCurrency[];
}

const InitialCryptoState: FavCrypto = {
  currencies: [],
};

type ActionType =
  | { type: "ADD"; crypto: CryptoCurrency }
  | { type: "REMOVE"; id: number };

const CryptoReducer = (state: FavCrypto, action: ActionType) => {
  if (action.type === "ADD") {
    const currencies = [...state.currencies];
    currencies.push(action.crypto);
    return { currencies };
  } else {
    const currencies = state.currencies.filter((x) => x.id !== action.id);
    return { currencies };
  }
};

export const FavContextProvider: React.FC = ({ children }) => {
  const [favCrypto, dispatcher] = useReducer(CryptoReducer, InitialCryptoState);

  const addCryptoHandler = (crypto: CryptoCurrency) => {
    dispatcher({ type: "ADD", crypto: crypto });
  };

  const removeCryptoHandler = (id: number) => {
    dispatcher({ type: "REMOVE", id: id });
  };
  const favContext = {
    currencies: favCrypto.currencies,
    onAddCrypto: addCryptoHandler,
    onRemoveCrypto: removeCryptoHandler,
  };
  return (
    <FavContext.Provider value={favContext}>{children}</FavContext.Provider>
  );
};

export const useCryptoContext = () => useContext(FavContext);

export default FavContext;
