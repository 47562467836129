import { ChangeEvent, FormEvent, useState } from "react";
import { Grid } from "semantic-ui-react";
import Input from "../../UI/common/form/Input";

type UserAccount = {
  username: string;
  password: string;
};

type Error<T> = {
  [property in keyof T]?: T[property];
};

const Login = () => {
  const [userAccount, setUserAccount] = useState<UserAccount>({
    password: "",
    username: "",
  });
  const [error, setError] = useState<Record<string, string> | null>(null);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    const err = validate();
    err === null ? setError(null) : setError(err);
  };

  const validate = () => {
    const error: Error<UserAccount> = {};
    if (userAccount.username.trim() === "")
      error.username = "Username is required!";
    if (userAccount.password.trim() === "")
      error.password = "Password is required!";
    return Object.keys(error).length > 0 ? error : null;
  };

  const validateProperty = (name: string, value: string) => {
    const error: Error<UserAccount> = {};
    if (value.trim() === "")
      error[name as keyof UserAccount] = `${name} is required!`;
    return Object.keys(error).length > 0 ? error : null;
  };

  const changeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setUserAccount({ ...userAccount, [e.target.name]: e.target.value });

    const { name, value } = e.target as EventTarget &
      HTMLInputElement & { name: keyof UserAccount; value: string };

    const err = validateProperty(name, value);

    err === null ? setError(null) : setError(err);
  };

  return (
    <form onSubmit={submitHandler}>
      <Grid columns={6} divided>
        <Grid.Column>
          <Input
            name="username"
            placeholder="username..."
            value={userAccount.username}
            onChangeHandler={changeInputHandler}
            error={error?.username}
          />

          <Input
            name="password"
            placeholder="password..."
            value={userAccount.password}
            onChangeHandler={changeInputHandler}
            type="password"
            error={error?.password}
          />
        </Grid.Column>
      </Grid>
      <button
        className="ui primary button"
        type="submit"
        disabled={validate() ? true : false}
      >
        Submit
      </button>
      {JSON.stringify({ error })}
    </form>
  );
};

export default Login;
