import styled from "styled-components";
import { useCryptoContext } from "../../../store/fav-context";
import Backdrop from "./Backdrop";

interface Props {
  onClose: () => void;
}

const StyledModal = styled.div`
  position: fixed;
  top: 20vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
`;

const Modal = ({ onClose }: Props) => {
  const { currencies } = useCryptoContext();
  return (
    <div>
      <Backdrop onClose={onClose} />
      <form>
        <StyledModal>
          {currencies.map((cur) => (
            <li>{cur.name}</li>
          ))}
        </StyledModal>
      </form>
    </div>
  );
};

export default Modal;
