import React, { FormEvent } from "react";

interface Props {
  searchHandler: (e: FormEvent<HTMLFormElement>) => void;
  clearSearchHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Search = React.forwardRef(
  ({ searchHandler, clearSearchHandler }: Props, searchRef: any) => {
    return (
      <form onSubmit={searchHandler}>
        <button
          type="button"
          className="ui button"
          onClick={clearSearchHandler}
        >
          clear
        </button>
        <div className="ui icon input">
          <input type="text" name="search" id="search" ref={searchRef} />
          <i aria-hidden="true" className="search icon"></i>
        </div>
      </form>
    );
  }
);

export default Search;
