import { NavLink, Link } from "react-router-dom";
import { Button, Menu } from "semantic-ui-react";
import styled from "styled-components";
import { useCryptoContext } from "../../../store/fav-context";

interface Props {
  onShowModal: () => void;
}

const StyledNavLink = styled(NavLink)`
  &.active {
    color: blueviolet;
  }
`;

const Navbar = ({ onShowModal }: Props) => {
  const { currencies } = useCryptoContext();
  return (
    <Menu borderless>
      <Menu.Header>
        <h1>
          <Link to="/">Coin Market</Link>
        </h1>
      </Menu.Header>
      <Menu.Item>
        <Button onClick={onShowModal}>
          My Favorite Crypto {currencies.length}
        </Button>
      </Menu.Item>
      <Menu.Item>
        <StyledNavLink to="/contact">Contat</StyledNavLink>
      </Menu.Item>
      <Menu.Item>
        <StyledNavLink to="/coin">Coin Table</StyledNavLink>
      </Menu.Item>
      <Menu.Item>
        <StyledNavLink to="/profile">Profile</StyledNavLink>
      </Menu.Item>
      <Menu.Item>
        <StyledNavLink to="/login">Login</StyledNavLink>
      </Menu.Item>
      <Menu.Item>
        <StyledNavLink to="/register">Register</StyledNavLink>
      </Menu.Item>
    </Menu>
  );
};

export default Navbar;
